//--------------------------------------------------------------
// BLOCK CONTENT
//--------------------------------------------------------------

.block-content {
	display: grid;
	gap: 30px;

	// Modifiers
	&--center {
		grid-template-columns: minmax(auto, 600px);
		justify-content: center;
	}

	&--left {
		@include mq($mq-tablet) {
			grid-template-columns: minmax(auto, 540px) minmax(auto, 540px);
		}
	}

	&--left img {
		grid-row: 1;
	}

	&--right {
		@include mq($mq-tablet) {
			grid-template-columns: minmax(auto, 600px) minmax(auto, 425px);
		}
	}

	// Image
	&__image {
		&--left {
			order: 1;

			@include mq($mq-tablet) {
				order: 0;
			}
		}

		&--right {
			order: 1;
		}
	}

	// Content
	&__content {
		max-width: 600px;

		&--left {
			@include mq($mq-tablet) {
				padding-right: 50px;
			}
		}

		&--right {
			@include mq($mq-tablet) {
				padding-left: 50px;
			}
		}
	}
}
