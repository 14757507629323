//--------------------------------------------------------------
// GLOBAL HEADER
//--------------------------------------------------------------

.g-header {
	background-color: rgba($color-white, 0.92);
	box-shadow: 0 2px 8px 0 rgba($color-black, 0.2);
	position: fixed;
	width: 100%;
	z-index: 2;

	&__container {
		align-items: center;
		display: grid;
		gap: 0 10px;
		grid-template-columns: 150px 1fr 52px;
		max-width: 1320px;
		padding: 0 10px;

		@include mq($mq-mobile-large) {
			gap: 20px 40px;
			grid-template-columns: 150px 130px 1fr;
		}
		@include mq($mq-tablet) {
			grid-template-columns: 150px 1fr 50px;
			padding: 0 20px;
		}
		@include mq($mq-laptop) {
			grid-template-columns: 150px 1fr 166px;
		}
	}

	&__logo {
		img {
			display: block;
			margin: 0;
		}
	}

	&__navigation {
		justify-self: end;

		@include mq($mq-laptop, false) {
			order: 1;
		}
	}

	&__register {
		text-align: center;
	}
}
