//--------------------------------------------------------------
// BLOCK COLUMN LAYOUT
//--------------------------------------------------------------

.block-column-layout {
	display: grid;
	gap: 30px;
	grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));

	&__column {
	}
}
