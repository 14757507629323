//--------------------------------------------------------------
// SLICK
//--------------------------------------------------------------

//-----------------------------------------
// Slick Theme
//-----------------------------------------
.slick-prev,
.slick-next {
	background-color: $color-white;
	border: 0;
	border-radius: 50%;
	box-shadow: 0 1px 2px 0 rgba($color-black, 0.24);
	font-size: 0;
	height: 30px;
	position: absolute;
	top: calc(50% - 15px);
	width: 30px;
	z-index: 1;

	svg {
		font-size: rem-calc(18);
	}
}

.slick-prev {
	left: 0;
}

.slick-next {
	right: 0;
}

.slick-dots {
	bottom: 15px;
	margin: 0;
}

.slick-current + .slick-active img {
	margin-left: 10px;
}

//-----------------------------------------
// Slick
//-----------------------------------------
.slick-slide {
	img {
		display: block;
		margin: 0;

		@include mq($mq-global-width) {
			max-width: 560px;
		}
	}
}
