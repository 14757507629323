//--------------------------------------------------------------
// COMPONENT DONATION
//--------------------------------------------------------------

.c-donation {
	background: linear-gradient(180deg, rgba($color-white, 0.5) 0%, rgba($color-primary, 0.2) 100%);
	position: relative;
	top: 81px;

	&__row {
		align-items: center;
		display: grid;
		grid-template-columns: 1fr;

		@include mq($mq-tablet) {
			gap: 20px 100px;
			grid-template-columns: 290px 1fr;
		}
	}

	&__image-container {
		align-self: end;

		@include mq($mq-tablet, false) {
			display: none;
		}
	}

	&__image {
		display: block;
	}

	&__content {
		@include mq($mq-tablet, false) {
			margin-bottom: 30px;
		}
	}
}
