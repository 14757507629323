//--------------------------------------------------------------
// BLOCK CAMPAIGN
//--------------------------------------------------------------

.block-campaign {
	display: grid;
	grid-template-columns: 1fr;

	@include mq($mq-laptop) {
		grid-template-columns: 380px 1fr;
	}

	&__image-container {
	}

	&__image {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	&__content-background {
		background: linear-gradient(180deg, rgba($color-white, 0.5) 0%, rgba($color-black, 0.2) 100%);
		border-bottom-right-radius: 5px;
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr;
		padding: 40px 35px;

		@include mq($mq-tablet) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include mq($mq-laptop, false) {
			border-bottom-left-radius: 5px;
		}
	}

	&__content {
		margin-bottom: 30px;
	}

	&__buttons {
		align-items: center;
		display: flex;
		flex-direction: column;

		@include mq($mq-tablet) {
			flex-direction: row;
		}
	}

	&__or {
		font-family: $font-bitter;
		font-size: rem-calc(24);
		font-weight: $font-weight-bold;
		margin: 5px 0;

		@include mq($mq-tablet) {
			margin: 0 20px;
		}
	}
}

//-----------------------------------------
// Goal
//-----------------------------------------
.block-campaign {
	&__goal-container {
		display: grid;
		grid-template-columns: 1fr;

		@include mq($mq-tablet) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__goal-progress-container {
	}

	&__goal-title {
		color: $color-red;
		font-size: rem-calc(16);
		font-weight: $font-weight-black;
		text-align: center;
		text-transform: uppercase;
	}

	&__goal-title-span {
		display: block;
		font-size: rem-calc(22);
	}

	&__goal-stats {
		align-self: center;
	}

	&__goal-stats-text {
		font-size: rem-calc(16);
		text-align: right;
	}

	&__goal-donor-percentage {
		display: block;
		font-size: rem-calc(24);
		font-weight: $font-weight-black;
	}

	&__goal-donor-count {
		display: block;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	&__goal-progress-container {
		@include mq($mq-tablet, false) {
			order: -1;
		}
	}

	&__goal-progress-bar-container {
		align-items: center;
		display: flex;
		height: 200px;
		justify-content: center;
	}

	// <progress>
	&__goal-progress-bar {
		border: 0;
		border-radius: 40px;
		color: $color-red; // Edge
		max-height: 34px;
		max-width: 200px;
		min-height: 34px;
		min-width: 200px;
		transform: rotate(-90deg);

		// Chrome
		&::-webkit-progress-bar {
			background-color: rgba($color-red, 0.2);
			border-radius: 40px;
		}

		// Mozilla
		&::-moz-progress-bar {
			background-color: rgba($color-red, 0.2);
			border-radius: 40px;
		}

		// Color of actual progress.
		&::-webkit-progress-value {
			background: linear-gradient(90deg, rgba($color-red, 0.5) 0%, rgba($color-red, 1) 100%);
			border-radius: 40px;
		}
	}
}
