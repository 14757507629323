//--------------------------------------------------------------
// GLOBAL PAGE HEADER
//--------------------------------------------------------------

.g-page-header {
	margin-bottom: 50px;

	&__image-container {
		position: relative;
	}

	&__image {
		display: block;
	}
}

//-----------------------------------------
// Tribute
//-----------------------------------------
.g-page-header {
	&__row {
		&--tribute {
			justify-content: flex-end;
		}
	}

	&__tribute-name {
		margin: 80px auto 0;
		max-width: 750px;
	}
}

//-----------------------------------------
// Post
//-----------------------------------------
.g-page-header {
	&__row {
		&--post {
			justify-content: flex-end;
		}
	}

	&__post {
		color: $color-black;
		margin: 80px auto 0;
		max-width: 750px;
	}

	&__post-details {
		display: flex;
		margin: 40px 0;

		& > * {
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}

	&__post-author {
		color: $color-primary;
		font-family: $font-bitter;
		font-size: rem-calc(14);
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}
}
