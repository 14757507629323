//--------------------------------------------------------------
// BLOCK GALLERY
//--------------------------------------------------------------

.block-gallery {
	// <ul>
	&__slider {
	}

	// <ul>
	&__slide {
		max-width: 560px;
	}

	&__image {
	}
}
