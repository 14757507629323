//--------------------------------------------------------------
// POSTS
//--------------------------------------------------------------

.post-footer {
	margin-bottom: 50px;
}

//-----------------------------------------
// Date
//-----------------------------------------
.date {
	color: $color-primary;
	font-family: $font-bitter;
	font-size: rem-calc(14);
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

//-----------------------------------------
// Categories
//-----------------------------------------
.categories {
	a {
		background-color: rgba($color-primary, 0.1);
		border-radius: 30px;
		display: inline-flex;
		flex-wrap: wrap;
		font-size: rem-calc(14);
		font-weight: $font-weight-bold;
		margin-bottom: 30px;
		padding: 5px 15px;
		text-transform: uppercase;

		&:not(:last-child) {
			margin: 0 4px 4px 0;
		}
	}
}

//-----------------------------------------
// Tags
//-----------------------------------------
.tags {
	span {
	}
}
