//--------------------------------------------------------------
// BUTTONS
//--------------------------------------------------------------

.button {
	appearance: none;
	background: linear-gradient(155.87deg, $color-primary 0%, $color-buddha-gold 100%);
	border: 1px solid transparent;
	border-radius: $global-radius;
	box-shadow: 0 10px 20px 0 rgba($color-black, 0.2);
	color: $color-black;
	cursor: pointer;
	display: inline-block;
	font-family: $font-bitter;
	font-size: rem-calc(18);
	font-weight: bold;
	line-height: 1;
	margin: 0;
	padding: 1px;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	width: auto;

	&:focus {
		outline: 0;
		padding: 2px;
	}

	&:hover {
		.button__faux {
			background: linear-gradient(155.87deg, $color-primary 0%, $color-buddha-gold 100%);
			color: $color-white;
		}
	}

	&:active {
		box-shadow: none;
	}

	// Required for primary button usage.
	&__faux {
		background: $color-white;
		border-radius: 28px;
		display: block;
		padding: 21px 40px;
	}

	&--secondary {
		background: $color-primary;
		box-shadow: none;
		color: rgba($color-white, 1);
		font-size: rem-calc(14);
		padding: 12px 28px;

		&:focus,
		&:hover {
			background: $color-primary;
			color: $color-white;
		}

		&:focus {
			box-shadow: 0 0 5px 2px rgba($color-black, 0.2), 0 0 5px 2px rgba(0,178,169, 0.2);
			padding: 12px 28px;
		}
	}

	&--full-width {
		display: block;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	&--alt {
		background: $color-white;
		box-shadow: none;
		color: $color-theme-gray;
		font-size: rem-calc(14);
		padding: 12px 32px;

		&:focus,
		&:hover {
			background: $color-white;
			color: $color-black;
		}

		&:focus {
			box-shadow: 0 0 5px 2px rgba($color-white, 0.6);
			padding: 12px 32px;
		}
	}

	&--reset {
		background-color: transparent;
		border: 0;
	}

	&--input {
		padding: 17px 32px;
	}

	&--header-register {
		padding: 4px;

		@include mq($mq-mobile-large, false) {
			font-size: 12px;
		}
		@include mq($mq-medium) {
			padding: 12px 28px;
		}
	}

	&[disabled] {
		background: $color-white;
		border: 1px solid $color-theme-gray;
		color: $color-theme-gray;
		cursor: default;
		padding: 21px 40px;
	}
}

//-----------------------------------------
// Contact Form 7 - Submit
//-----------------------------------------
.wpcf7-submit {
	@extend .button;

	background: $color-primary;
	box-shadow: none;
	color: rgba($color-white, 0.7);
	font-size: rem-calc(14);
	padding: 12px 32px;

	&:focus,
	&:hover {
		background: $color-primary;
		color: $color-white;
	}

	&:focus {
		box-shadow: 0 0 5px 2px rgba($color-black, 0.2), 0 0 5px 2px rgba(0,178,169, 0.2);
		padding: 12px 32px;
	}
}
