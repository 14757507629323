.alert-banners {
  width: 100%;
  position: absolute;
  top: 94px;
  left: 0;
  z-index: 1;
}

.alert-banner {
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 22px;
  font-family: Bitter,serif;
  font-weight: bold;
}
