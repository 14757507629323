//--------------------------------------------------------------
// COMPONENT SOCIAL MEDIA
//--------------------------------------------------------------

.c-social-media {
	display: inline-flex;
	flex-direction: column;

	// <li>
	&__item {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	// <a>
	&__link {
		background-color: $color-primary;
		border-radius: 50%;
		color: $color-white;
		display: block;
		font-size: rem-calc(20);
		min-width: 31px;
		padding: 7px;

		&:focus,
		&:hover {
			background-color: $color-white;
			color: $color-primary;
		}
	}

	svg {
		display: block;
		margin: auto;
		transition: transform 0.2s ease-in-out;
	}
}

//-----------------------------------------
// Footer
//-----------------------------------------
.c-social-media {
	$social: &;

	&--footer {
		flex-direction: row;

		// <li>
		#{$social}__item {
			&:not(:last-child) {
				margin: 0 20px 0 0;
			}
		}

		// <a>
		#{$social}__link {
			background-color: $color-white;
			color: $color-black;

			&:focus,
			&:hover {
				color: $color-primary;
			}
		}
	}
}

//-----------------------------------------
// Hero
//-----------------------------------------
.c-social-media {
	&--hero {
		display: none;

		@include mq(1290px) {
			display: block;
			position: fixed;
			right: 20px;
			top: 140px;
		}
	}
}
