//--------------------------------------------------------------
// GLOBAL LAYOUT MAIN
//--------------------------------------------------------------

.g-l-main {
	min-height: 100vh;
	padding-top: 50px;
	position: relative;
	width: 100%;

	@include mq($mq-full) {
		padding-top: 94px;
	}
}
