//--------------------------------------------------------------
// COMPONENT PAGE CONTENT
//--------------------------------------------------------------

.c-page-content {
	margin-bottom: 80px;

	& > * {
		margin-left: auto !important;
		margin-right: auto !important;
		max-width: 750px;
	}

	.block-column-layout {
		max-width: $global-width;
	}

	// Register Page
	.page-register & {
		& > * {
			margin-left: auto;
			margin-right: auto;
			max-width: $global-width;
		}
	}
}

.closing_ask {
	font-family: $font-bitter;
	font-size: 20px;
	font-style: italic;
	letter-spacing: -0.27px;
	line-height: 27px;

	&:before {
		background-color: $color-buddha-gold;
		content: '';
		display: block;
		height: 3px;
		margin-bottom: 24px;
		width: 56px;
	}
}
