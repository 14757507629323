//--------------------------------------------------------------
// MAIN HERO
//--------------------------------------------------------------

.main-hero {
	align-items: center;
	min-height: 600px;
	width: 100%;

	@include mq($mq-tablet) {
		min-height: 1200px;
	}

	&.lazyloaded {
		display: flex;
	}

	&__content {
		max-width: 400px;
	}
}
