//--------------------------------------------------------------
// LEARN MORE
//--------------------------------------------------------------

.learn-more {
	margin-bottom: 150px;
	text-align: center;

	&__image {
		margin-bottom: 30px;
	}

	&__heading {
		font-weight: $font-weight-normal;
		line-height: 1.5;
		margin-bottom: 40px;
	}
}
