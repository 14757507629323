//--------------------------------------------------------------
// WORDPRESS
// WordPress Specific Elements Inserted Into WYSIWYG
//--------------------------------------------------------------

//-----------------------------------------
// Admin Bar
//-----------------------------------------
.admin-bar {
}

//-----------------------------------------
// Other Required Classes
//-----------------------------------------
.sticky {
}

.screen-reader-text {
	@include visual-hide;
}

.gallery-caption {
}

.bypostauthor {
}

//-----------------------------------------
// Page Numbers
//-----------------------------------------
ul.page-numbers {
	display: flex;
	justify-content: center;
	margin: 0 0 70px 0;

	li {
		display: inline-block;
		text-align: center;

		&:not(:last-child) {
			margin-right: 10px;
		}

		.page-numbers {
			background-color: $color-white;
			border: 2px solid $color-dusty-gray;
			border-radius: 50%;
			color: $color-dusty-gray;
			display: block;
			font-size: rem-calc(18);
			font-weight: $font-weight-bold;
			min-width: 51px;
			padding: 10px;

			&:focus,
			&:hover {
				border-color: $color-green;
				color: $color-black;
			}
		}

		.current {
			border-color: $color-green;
			color: $color-black;
			padding: 10px;
		}
	}
}
