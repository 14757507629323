//--------------------------------------------------------------
// COMPONENT BANNER COUNTER
//--------------------------------------------------------------

.c-banner-counter {
	background-color: $color-buddha-gold;
	color: $color-white;
	font-family: $font-bitter;
	font-size: 22px;
	font-weight: bold;
	padding: 13px 20px;
	text-align: center;

	&__italic {
		display: inline-block;
		font-size: 18px;
		font-style: italic;
		font-weight: normal;
		margin-left: 18px;
	}
}
