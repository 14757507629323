//--------------------------------------------------------------
// BACKGROUND DIAMOND
//--------------------------------------------------------------

.background-diamond {
	background: linear-gradient(180deg, rgba($color-white, 1) 0%, rgba(0, 174, 170, 0.3) 52.22%, rgba($color-white, 1) 100%),
	url('../images/pattern-diamond.svg') center;
	margin-bottom: 50px;
	padding: 50px 0 160px;

	&__subtext {
		font-size: rem-calc(24);
		font-weight: $font-weight-bold;
		margin-bottom: 70px;
		text-align: center;
	}
}
