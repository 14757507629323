//--------------------------------------------------------------
// HELPERS
//--------------------------------------------------------------

.h-visual-hide {
	@include visual-hide;
}

.h-reverse-visual-hide {
	@include reverse-visual-hide;
}

.h-hidden {
	display: none;
}

.h-clearfix {
	@include clearfix;
}

//-----------------------------------------
// Cover
//-----------------------------------------
.h-cover-absolute {
	bottom: 0;
	height: 100%;
	left: 0;
	min-height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.h-cover-background {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.h-cover-video {
	height: auto;
	max-height: none;
	max-width: none;
	min-height: 100%;
	min-width: 100%;
	width: auto;
}

.h-iframe-container {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;

	iframe {
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

//-----------------------------------------
// Text
//-----------------------------------------
.h-text-center {
	text-align: center;
}

.h-text-bold {
	font-weight: $font-weight-bold;
}

.h-text-large {
	font-size: rem-calc(19);
	font-weight: $font-weight-light;
}

.h-text-small {
	font-size: rem-calc(15);
}

.h-text-secondary {
	color: $color-theme-gray;
	font-size: rem-calc(14);
}

//-----------------------------------------
// Colors
//-----------------------------------------
.h-color-primary {
	color: $color-primary;
}

.h-color-secondary {
	color: $color-secondary;
}

.h-color-tertiary {
	color: $color-tertiary;
}
