//--------------------------------------------------------------
// SETTINGS
//--------------------------------------------------------------

//-----------------------------------------
// Global
//-----------------------------------------
$global-width: 1180px;
$global-padding: 20px;
$global-radius: 30px;
$global-spacing: 25px;

//-----------------------------------------
// Media Queries
//-----------------------------------------
$mq-mobile-medium: 375px;
$mq-mobile-large: 425px;
$mq-medium: 640px;
$mq-tablet: 768px;
$mq-wordpress: 783px;
$mq-full: 960px;
$mq-laptop: 1024px;
$mq-global-width: $global-width;
$mq-laptop-large: 1440px;

//-----------------------------------------
// Fonts
//-----------------------------------------
$font-bitter: 'Bitter', serif;
$font-roboto: 'Roboto', sans-serif;

// Family
$font-family-body: $font-roboto;
$font-family-heading: $font-bitter;

// Size
$font-size-content: 20px;

// Weight
$font-weight-light: 300; // Roboto
$font-weight-normal: 400; // Bitter, Roboto
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700; // Bitter
$font-weight-black: 900;

//-----------------------------------------
// Colors
//-----------------------------------------
// Neutrals
$color-black: #000000;
$color-white: #FFFFFF;
$color-gray: #808080;

// Theme Colors
$color-monza: #C5003E;
$color-flush-orange: #FF8200;
$color-gold: #FFD100;
$color-buddha-gold: #BFB800;
$color-vida-loca: #658D1B;
$color-persian-green: #00B2A9;
$color-endeavour: #005EB8;
$color-plum: #93328E;
$color-prussian-blue: #002E5A;
$color-midnight: #001D38;
$color-alabaster: #FAFAFA;
$color-dusty-gray: #9B9B9B;

// Roles
$color-red: $color-monza;
$color-orange: $color-flush-orange;
$color-yellow: $color-gold;
$color-green: $color-vida-loca;
$color-teal: $color-persian-green;
$color-blue: $color-endeavour;
$color-violet: $color-plum;
$color-dark-blue: $color-prussian-blue;
$color-dark-blue-1: $color-midnight;
$color-primary: $color-teal;
$color-secondary: $color-dark-blue;
$color-tertiary: $color-dark-blue-1;
$color-theme-black: $color-black;
$color-theme-white: $color-alabaster;
$color-theme-gray: #4A4A4A;

// Brand color order.
$colors: (1: $color-red, 2: $color-orange, 3: $color-yellow, 4: $color-buddha-gold, 5: $color-green, 6: $color-teal, 7: $color-blue, 8: $color-violet);

// Alerts
$color-error: $color-red;
$color-warning: $color-yellow;
$color-success: $color-green;
