//--------------------------------------------------------------
// NAVIGATION
//--------------------------------------------------------------

.navigation {
	position: relative;
	z-index: 99;
}

//-----------------------------------------
// Menu
//-----------------------------------------
.navigation {
	$nav: &;

	// <ul>
	&__menu {
		display: none;
		margin: 0;
		padding: 0;

		@include mq($mq-laptop) {
			display: flex;
		}

		& > #{$nav}__item {
			@each $key, $color in $colors {
				&:nth-child(#{$key}) {
					@include mq($mq-laptop) {
						border-top: 3px solid $color;
					}
				}
			}

			&:hover {
				@include mq($mq-laptop) {
					border-top-width: 16px;
				}

				& > a {
					@include mq($mq-laptop) {
						padding-top: 21px;
					}
				}
			}

			&:not(:last-child) {
				@include mq($mq-laptop) {
					margin-right: 40px;
				}
			}
		}
	}

	// <li>
	&__item {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;

		// Current page link.
		&.current-menu-item {
			a {
				color: $color-primary;
			}
		}
	}

	// <a>
	a {
		color: $color-black;
		display: block;
		font-family: $font-bitter;
		font-size: rem-calc(14);
		padding: 35px 0;

		&:focus,
		&:hover {
			font-weight: $font-weight-bold;
		}

		&::after {
			content: attr(title);
			display: block;
			font-weight: $font-weight-bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	// Dots
	.navigation__item--more a {
		align-items: center;
		display: flex;
		min-width: 59px;

		&::after {
			display: none;
		}

		.more-dots {
			font-size: rem-calc(20);
			margin-left: 3px;
		}
	}
}

//-----------------------------------------
// Menu - Sub Menu - Parent
//-----------------------------------------
.navigation {
	$nav: &;

	// <li>
	&__sub-menu-parent {
		&:hover {
			@include mq($mq-laptop) {
				border-top: 16px solid;
			}

			// Keep parent <li> appearing hovered.
			& > a {
				font-weight: $font-weight-bold;
			}

			#{$nav}__sub-menu {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

//-----------------------------------------
// Menu - Sub Menu
//-----------------------------------------
.navigation {
	&__sub-menu {
		@include mq($mq-laptop) {
			background-color: rgba($color-white, 0.92);
			border-radius: 5px;
			box-shadow: 0 14px 20px 0 rgba($color-black, 0.2);
			left: -50px;
			margin: 0;
			opacity: 0;
			padding: 35px 50px;
			position: absolute;
			transition: opacity 0.3s ease-in;
			visibility: hidden;
			z-index: 98;
		}

		a {
			color: $color-black;
			display: block;
			font-size: rem-calc(14);
			padding: 15px;
			white-space: nowrap;

			@include mq($mq-laptop) {
				padding: 15px 0;
			}
		}
	}
}

//-----------------------------------------
// Menu - Description
//-----------------------------------------
.navigation {
	&__description {
		display: block;
		font-size: rem-calc(9);
		padding-left: 5px;
	}
}

//-----------------------------------------
// Menu - Active
//-----------------------------------------
.navigation {
	&__menu.is-active {
		background-color: $color-primary;
		display: block;
		height: 100%;
		left: 0;
		min-width: 230px;
		overflow-y: auto;
		position: fixed;
		top: 0;
		z-index: 99;

		li {
			a {
				color: $color-theme-white;

				&:hover,
				&:focus {
					background-color: darken($color-primary, 20%);
				}
			}
		}

		& > li {
			& > a {
				background-color: $color-secondary;
				font-weight: $font-weight-bold;
				padding: 10px;
			}

			@each $key, $color in $colors {
				&:nth-child(#{$key}) {
					& > a {
						background-color: $color;
					}
				}
			}
		}

		.level-1 {
			background-color: lighten($color-primary, 5%);
			margin: 0;

			a {
				font-size: rem-calc(13);
				padding: 13px 8px 13px 25px;
			}
		}
	}
}

//-----------------------------------------
// Menu - Active - Sub Menu
//-----------------------------------------
.navigation {
	$nav: &;

	&__menu.is-active {
		#{$nav}__sub-menu {
			position: relative;
		}
	}
}

//-----------------------------------------
// Menu Trigger
//-----------------------------------------
.navigation {
	&__menu-trigger {
		color: $color-primary;
		cursor: pointer;
		display: block;
		font-size: rem-calc(30);
		line-height: 1;
		padding: 10px;

		@include mq($mq-laptop) {
			display: none;
		}

		&:hover,
		&:focus {
			color: $color-primary;
		}
	}
}

//-----------------------------------------
// Level 1
//-----------------------------------------
.level-1 {
	margin-left: 20px;

	a {
		color: $color-dusty-gray;
	}
}
