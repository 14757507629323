//--------------------------------------------------------------
// COMPONENT CARDS
//--------------------------------------------------------------

.c-cards {
	$cards: &;

	align-items: start;
	display: grid;
	gap: 35px 24px;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

	&__card {
		border-radius: 5px;
		box-shadow: 0 10px 20px 0 rgba($color-black, 0.2);

		@each $key, $color in $colors {
			&:nth-child(#{$key}) {
				#{$cards}__container {
					border-color: $color;
				}

				#{$cards}__decor {
					color: $color;
				}

				#{$cards}__link {
					&:focus,
					&:hover {
						color: $color;

						#{$cards}__image-overlay {
							background-color: rgba($color, 0.2);
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}

	&__image-container {
		position: relative;
	}

	&__image-overlay {
		background-color: rgba($color-red, 0.2); // Default
		border-radius: 5px 5px 0 0;
		height: 100%;
		left: 0;
		opacity: 1;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: 100%;
	}

	&__info {
		padding: 40px;
	}

	&__container {
		align-items: center;
		background-color: $color-white;
		border-radius: 0 0 5px 5px;
		border-right: 24px solid $color-red; // Default
		display: flex;
		position: relative;
	}

	&__image {
		border-radius: 5px 5px 0 0;
		display: block;
		max-height: 335px;
		width: 100%;
	}

	&__title {
		font-family: $font-bitter;
		font-size: 24px;
		font-weight: $font-weight-bold;
		margin-bottom: 10px;
		padding-right: 15px;

		&--news {
			font-size: rem-calc(15);
			margin-bottom: 10px;
			position: relative;
			z-index: 1;
		}

		&.tribute {
			font-size: 16px;
			margin-bottom: 0;
			position: relative;
			z-index: 1;
		}
	}

	&__description {
		font-size: 16px;
	}

	&__type {
		font-family: $font-roboto;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.9px;
		text-transform: uppercase;
	}

	// <svg>
	&__decor {
		background-color: $color-white;
		border-radius: 50%;
		display: block;
		height: 30px;
		padding: 5px 10px;
		position: absolute;
		right: -10px;
		width: 30px !important;
	}
}

//-----------------------------------------
// 4 Column
//-----------------------------------------
.c-cards {
	$card: &;

	&--4-column {
		gap: 24px 13px;
		grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

		@include mq($mq-global-width) {
			grid-template-columns: repeat(auto-fit, minmax(270px, 275px));
		}

		#{$card}__info {
			padding: 13px 0 13px 20px;
		}
	}
}

//-----------------------------------------
// Modifier
//-----------------------------------------
.c-cards {
	// Home Page
	&--home-overlap {
		transform: translateY(-100px);
	}

	// Tributes Page
	&--pagination-margin {
		margin-bottom: 70px;
	}

	// News & Events Page
	&--news {
		margin-bottom: 100px;
	}
}
