//--------------------------------------------------------------
// WYSIWYG CONTENT
//--------------------------------------------------------------

//-----------------------------------------
// Headings
//-----------------------------------------
.wysiwyg-content {
	h2 {
		margin-bottom: 40px;
	}

	h3 {
		margin-bottom: 24px;
	}
}

//-----------------------------------------
// Paragraph
//-----------------------------------------
.wysiwyg-content {
	p {
		margin: 0 0 $global-spacing 0;
	}
}

//-----------------------------------------
// List
//-----------------------------------------
.wysiwyg-content {
	ul,
	ol {
		margin: 0 0 $global-spacing 0;
	}

	// Unordered List
	ul {
		list-style-type: disc;
		padding: 0 ($global-padding * 2) 0 $global-padding;
	}

	// Ordered List
	ol {
		list-style-position: inside;
		list-style-type: decimal;
		padding: 0 ($global-padding * 2) 0 0;
	}
}

//-----------------------------------------
// List Item
//-----------------------------------------
.wysiwyg-content {
	li {
		&:not(:last-child) {
			margin-bottom: ($global-spacing / 2);
		}
	}
}

//-----------------------------------------
// Blockquote
//-----------------------------------------
.wysiwyg-content {
	blockquote {
		color: $color-primary;
		font-family: $font-bitter;
		font-size: rem-calc(34);
		font-style: italic;
		margin: 0 auto 30px;
		padding: 0;

		& > p {
			color: inherit;
			font-family: inherit;
			font-size: inherit;
			font-style: inherit;
			line-height: 1.5;
		}
	}
}

//-----------------------------------------
// Short Quotation
//-----------------------------------------
.wysiwyg-content {
	q {
		color: $color-primary;
		font-family: $font-bitter;
		font-size: rem-calc(34);
		font-style: italic;
	}
}

//-----------------------------------------
// Cite
//-----------------------------------------
.wysiwyg-content {
	cite {
		color: $color-primary;
		display: block;

		&::before {
			content: '\2014 \0020';
		}
	}
}

//-----------------------------------------
// Horizontal Rule
//-----------------------------------------
.wysiwyg-content {
	hr {
		margin: 24px auto;
	}
}

//-----------------------------------------
// Form
//-----------------------------------------
.wysiwyg-content {
	form {
	}

	input {
		margin-bottom: 20px !important;
	}

	.radio-group {
		label {
			font-weight: $font-weight-normal;
		}

		input {
			margin-bottom: 0 !important;
		}
	}
}

//-----------------------------------------
// Anchor
//-----------------------------------------
.wysiwyg-content {
	a {
		position: relative;

		&:not(.button):not(.wp-block-button__link) {
			color: $color-primary;
			text-decoration: underline;

			&:focus,
			&:hover {
				color: $color-secondary;
			}
		}
	}
}

//-----------------------------------------
// Image
//-----------------------------------------
.wysiwyg-content {
	img {
		display: block !important;
		height: auto;
		line-height: 0;
		width: auto;
	}
}
