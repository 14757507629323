//--------------------------------------------------------------
// DONOR STATS
//--------------------------------------------------------------

.donor-stats {
	$donor: &;

	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	margin-bottom: 150px;

	&__button {
		align-items: center;
		background: linear-gradient(180deg, rgba($color-white, 0) 3.92%, rgba(0, 94, 184, 0.2) 96.66%);
		background-color: $color-primary;
		border: 0;
		border-radius: 5px;
		color: $color-white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 340px;
		padding: 70px 40px 0;
		text-align: center;
		width: 100%;
	}

	a {
		&:focus,
		&:hover {
			color: darken($color-white, 10%);
		}
	}

	&__icon {
		display: block;
		font-size: rem-calc(35);
		margin: 0 auto 30px;
	}

	&__text-can {
		display: block;
		font-size: rem-calc(20);
		font-weight: $font-weight-light;
	}

	&__message {
		display: block;
		font-family: $font-bitter;
		font-size: rem-calc(24);
		font-weight: $font-weight-bold;
	}
}

//-----------------------------------------
// Heading
//-----------------------------------------
.donor-stats-heading {
	color: $color-primary;
	margin-bottom: 70px;
	text-align: center;
}
