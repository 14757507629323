//--------------------------------------------------------------
// COMPONENT TRIBUTES
//--------------------------------------------------------------

.c-tributes {
	&__heading {
		margin-bottom: 40px;
		text-align: center;
	}

	&__search-container {
		align-items: center;
		display: block;
		margin-bottom: 40px;
		@include mq($mq-tablet) {
			flex-direction: row;
			display: flex;
			justify-content: center;
			margin-bottom: 80px;
		}

	}

	&__search {
		align-items: flex-end;
		display: flex;
		margin-right: 40px;
	}

	&__search-input {
		margin-right: 10px;
	}
}
