//--------------------------------------------------------------
// GLOBAL FOOTER
//--------------------------------------------------------------

.g-footer {
	color: $color-white;
	margin-top: 80px;
	width: 100%;
}

//-----------------------------------------
// Top
//-----------------------------------------
.g-footer {
	&__top-container {
		background-color: $color-secondary;
		padding: 30px 0 40px;
	}

	&__top {
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr;

		@include mq($mq-laptop) {
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		}
	}

	&__details {
		@include mq($mq-laptop, false) {
			justify-self: center;
		}
	}

	&__details-links {
		display: flex;
		flex-wrap: wrap;
		margin: 23px 0 30px;

		li:not(:last-child) {
			margin: 0 30px 10px 0;
		}
	}

	&__address {
		align-items: flex-end;
		display: flex;
	}

	&__logo-donate-life {
		margin: 0 20px 0 0;
	}

	&__contact-fields {
		align-items: center;
		display: flex;
		flex-direction: column;

		@include mq($mq-mobile-large) {
			flex-direction: row;
		}
	}

	// <input>
	&__contact-email {
		background-color: $color-primary;
		color: $color-white;
		margin-bottom: 20px;

		@include mq($mq-mobile-large) {
			margin: 0 30px 0 0;
		}

		&::placeholder {
			color: $color-white;
		}
	}

	&__contact-label {
		margin-left: 24px;
	}

	&__contact-links {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		line-height: 1;

		@include mq($mq-tablet) {
			flex-direction: row;
		}
	}

	&__contact-item {
		font-size: rem-calc(17);

		&:not(:last-child) {
			margin-bottom: 20px;

			@include mq($mq-tablet) {
				border-right: 1px solid white;
				height: 30px;
				line-height: 30px;
				margin-bottom: 0;
				margin-right: 20px;
				padding-right: 20px;
			}
		}
	}

	&__subscribe-container {
		margin-bottom: 40px;
	}
}

//-----------------------------------------
// Bottom
//-----------------------------------------
.g-footer {
	&__bottom {
		background-color: $color-tertiary;
		padding: 40px 0;

		&-row {
			display: flex;
			justify-content: space-between;
		}

		&-back-top {
			color: $color-white;
			font-size: rem-calc(15);

			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__copyright {
		font-size: rem-calc(14);
		text-align: center;
	}
}
