//--------------------------------------------------------------
// FEATURED NEWS
//--------------------------------------------------------------

.featured-news {
	display: grid;
	grid-template-columns: 1fr;
	margin-bottom: 30px;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 1fr;
	}

	&__image-container {
		align-self: end;
	}

	&__image {
	}

	&__content-container {
		align-items: flex-start;
		background-color: $color-blue;
		border-bottom-right-radius: 5px;
		color: $color-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px 35px;

		@include mq($mq-laptop, false) {
			border-bottom-left-radius: 5px;
		}
		@include mq($mq-laptop) {
			border-top-right-radius: 5px;
		}
	}

	&__content {
		margin-bottom: 30px;
	}

	&__title {
		color: $color-white;
		margin-bottom: 20px;
	}

	&__read-more {
		color: $color-white;
		display: inline-block;
		font-size: rem-calc(12);
		text-decoration: none;
		text-transform: uppercase;
	}
}
