//--------------------------------------------------------------
// BLOCK DONATE
//--------------------------------------------------------------

.block-donate {
	display: grid;
	grid-template-columns: 1fr;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 1fr;
	}

	&__image-container {
		align-self: end;
	}

	&__image {
	}

	&__content-container {
		background: linear-gradient(180deg, rgba($color-white, 0.5) 0%, rgba($color-primary, 0.2) 100%);
		border-bottom-right-radius: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px 35px;

		@include mq($mq-laptop, false) {
			border-bottom-left-radius: 5px;
		}
	}

	&__content {
		margin-bottom: 30px;
	}

	&__buttons {
		align-items: center;
		display: flex;
		flex-direction: column;

		@include mq($mq-tablet) {
			flex-direction: row;
		}
	}

	&__or {
		font-family: $font-bitter;
		font-size: rem-calc(24);
		font-weight: $font-weight-bold;
		margin: 5px 0;

		@include mq($mq-tablet) {
			margin: 0 20px;
		}
	}
}
